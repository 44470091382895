import React from "react";
import { socialMedia } from "../../modules/blogs/constants/blogs";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="bg-black py-20">
      <div className="md:w-[40%] m-auto flex flex-col justify-center items-center">
        <div className="flex gap-5">
          {socialMedia?.map((data) => (
            <a
              href={data?.link}
              key={data?.id}
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <img src={data?.img} alt="img" className="cursor-pointer" />
            </a>
          ))}
        </div>
        <p className="text-[#979797] font-[700] md:text-[1.85vw] pt-3">
          &copy; GainWix {year}, All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
