import { ICONS } from "../../../assets/icons";

export const blogContent = [
  {
    id: 1,
    img: ICONS?.blogTsunami,
    routeTitle: "your_digital_agency's_survival_guide",
    title: "The AI Tsunami : Your Digital Agency's Survival Guide",
    button: "Read now",
    date: "January 2, 2025",
    ceo: "Vivek",
    role: "CEO of GainWix.ai",
    imgBlog: ICONS?.tsunami,
    blogContent: [
      {
        id: "ai-tsunami",
        title: "The AI Tsunami : Your Digital Agency's Survival Guide",
        content: [
          {
            id: 1,
            list: "The digital marketing landscape is not evolving; it's imploding. A tidal wave of artificial intelligence is crashing down, and if your agency isn't prepared, you're facing an existential threat. Industry analysts predict that 90% of traditional digital marketing agencies will become obsolete within five years if they fail to embrace AI. This isn't a prediction; it's a warning.(Note: While I can't provide a specific source for this statistic, you should find and cite a relevant industry report or analysis tosupport this claim.)",
          },
          {
            id: 2,
            list: "Last month, a mid-sized agency with a 15-year history filed for bankruptcy. Their downfall wasn't due to a lack of clients or poor management. It was their refusal to adopt AI-driven strategies. They were clinging to outdated tactics, while their competitors surfed the AI wave to success. Their story is a stark warning—a chilling premonition of what awaits agencies that fail to adapt.",
          },
          {
            id: 3,
            list: "AI isn't just a new tool; it's a complete paradigm shift. It's obliterating traditional marketing strategies, leaving behind agencies that refuse to change. Your competitors are already using AI to personalize campaigns, automate workflows, and predict market trends with terrifying accuracy. They're leaving you in their wake. The question isn't if AI will impact your agency, but when it will sink your ship.",
          },
          {
            id: 4,
            list: "Imagine this: Your client acquisition rate plummets. Your revenue   streams dry up. You're forced to lay off loyal employees, watching talented individuals struggle to find work in a rapidly changing market. This isn't hyperbole; it's the grim reality facing agencies who ignore the AI revolution. The clock is ticking. This isn't a suggestion; it's a survival imperative. You need to act now.",
          },
          {
            id: 5,
            list: "Let's be clear: This isn't about replacing humans with robots. It's about augmenting human creativity and strategic thinking with the power of AI. Those who learn to harness this power will thrive. Those who don't will be washed away. Consider the example of [Insert hypothetical or real-world example of a failing agency vs. a thriving agency using AI]. The difference is stark.",
          },
        ],
      },
      {
        id: "charting-course",
        title: "Charting a Course Through the Storm",
        img: ICONS?.tsunamiAgency,
        content: [
          {
            id: 1,
            list: " The good news is, you're not powerless. You can navigate this AI tsunami and not only survive but thrive. Here's how:",
          },
          {
            id: 2,
            list: "Implement a three-month AI training program: Focus on practical applications of AI tools like Google's AI Platform, Adobe Sensei, or similar platforms relevant to your agency's services. This isn't a luxury; it's a necessity.",
          },
          {
            id: 3,
            list: "Integrate AI-powered tools into your workflow: Explore tools for SEO optimization (like Surfer SEO or Market Muse), social media management (like Hootsuite Insights), and PPC campaign optimization (like Albert.ai). Automate repetitive tasks and free up your team to focus on strategic initiatives.",
          },
          {
            id: 4,
            list: "Develop AI-driven service offerings: Create new services that leverage AI's capabilities, such as predictive analytics for client campaigns, personalized content creation, or AI-powered chatbots for customer service.",
          },
          {
            id: 5,
            list: "Showcase your AI expertise: Highlight your agency's AI capabilities in your marketing materials and client presentations. Position yourselves as leaders in the AI-driven marketing space.",
          },
          {
            id: 6,
            list: "Let's address the elephant in the room: Cost and skill gaps. Implementing AI solutions requires an investment, but the ROI far outweighs the expense. Consider phased implementation, starting with tools that offer the greatest impact. Address the skills gap by investing in training, recruiting talent with AI expertise, or partnering with AI specialists.",
          },
        ],
      },
      {
        id: "glimmer-hope",
        title: "A Glimmer of Hope",
        img: ICONS?.glimmer,
        content: [
          {
            id: 1,
            list: " The threat is real, but so is the opportunity. This AI tsunami isn't just a destructive force; it's a wave that can lift your agency to unprecedented heights. The future of digital marketing is not about resisting AI; it's about mastering it.",
          },
          {
            id: 2,
            list: "The choice is yours: Let the AI tsunami wash you away, or learn to ride the wave to success. Don't wait until it's too late. Contact us today for a free consultation to discuss how we can help your agency navigate this critical transition. Let's chart a course through the storm together.",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    img: ICONS?.drivenCommerce,
    routeTitle: "The_Rise_of_Creator_Driven_Commerce_in_India",
    title:
      "The Rise of Creator-Driven Commerce in India : From Reels to Riches",
    button: "Read now",
    date: "February 18, 2025",
    ceo: "Vivek",
    role: "CEO of GainWix.ai",
    imgBlog: ICONS?.drivenCommerceBlog,
    blogContent: [
      {
        id: "driven-commerce",
        title:
          "The Rise of Creator-Driven Commerce in India: From Reels to Riches",
        img: ICONS?.drivenCommerceList,
        reverse: true,
        content: [
          {
            id: 1,
            list: "Imagine this: a street vendor with a secret recipe for delicious street food, initially selling to a small crowd. Over time, word-of-mouth spreads, lines get longer, and soon, the vendor becomes a local sensation. This is the essence of creator-driven commerce. Just like that street vendor built a loyal following around their unique offerings, today, individual content creators are building powerful brands, turning their influence into real-world sales.",
          },
          {
            id: 2,
            listTitle: "From Influencers to Entrepreneurs:",
            list: "Gone are the days when social media was just about sharing photos and connecting with friends. Today, platforms like Instagram, YouTube, and TikTok have transformed into powerful marketplaces. Creators, with their massive followings and engaged communities, are no longer just entertainers; they're becoming entrepreneurs, leveraging their influence to sell products and services directly to their audience.",
          },
          {
            id: 3,
            listTitle: "Indian Creators: Leading the Charge",
            list: "India is witnessing a meteoric rise in creator-driven commerce.",
            listData: [
              {
                id: 1,
                data: "Beauty and Fashion: Beauty gurus like Malvika Sitlani and Shreya Jain have launched their own makeup lines, while fashion influencers like Komal Pandey and Masoom Minawala collaborate with brands and even have their own clothing labels.",
              },
              {
                id: 2,
                data: "Gaming and Entertainment: Popular gamers like Dynamo and CarryMinati have expanded their reach by launching merchandise, hosting live streams with brands, and even venturing into esports.",
              },
              {
                id: 3,
                data: "Regional Powerhouses: The rise of vernacular content on platforms like Moj, Josh, and ShareChat has empowered regional creators. These creators are connecting with audiences in their native languages, building strong local communities, and driving sales within their respective regions.",
              },
            ],
          },
          {
            id: 4,
            listTitle: "Fueling the Fire: Technology and Trends",
            list: "Several factors are accelerating this growth:",
            list2:
              "Beauty and Fashion: Beauty gurus like Malvika Sitlani and Shreya Jain have launched their own makeup lines, while fashion influencers like Komal Pandey and Masoom Minawala collaborate with brands and even have their own clothing labels.",
            listData: [
              {
                id: 1,
                data: "Short-Form Video Boom: Platforms like Instagram Reels, YouTube Shorts, and TikTok have democratized content creation, making it easier for anyone to create engaging videos and reach a wider audience.",
              },
              {
                id: 2,
                data: "Rise of Social Commerce: Social media platforms are increasingly integrating e-commerce features, allowing creators to seamlessly sell products directly within their platforms.",
              },
              {
                id: 3,
                data: "AI-Powered Recommendations: AI algorithms personalize content feeds, ensuring that users see content from creators they are most likely to engage with, further amplifying their reach.",
              },
              {
                id: 4,
                data: "Live Shopping: Platforms like Instagram and Facebook are embracing live shopping, enabling creators to host interactive shopping experiences, answer questions in real-time, and build stronger connections with their audience.",
              },
            ],
          },
        ],
      },
      {
        id: "monetization",
        title: "Monetization Strategies: Beyond Brand Deals",
        img: ICONS?.monetization,
        content: [
          {
            id: 1,
            listTitle: "Monetization Strategies: Beyond Brand Deals",
            list: "Creators are exploring diverse monetization strategies beyond traditional brand collaborations:",
            listData: [
              {
                id: 1,
                data: "Direct-to-Fan Sales: Launching their own merchandise, offering exclusive content to subscribers, and selling digital products like eBooks or online courses.",
              },
              {
                id: 2,
                data: "Subscription Models: Creating exclusive membership communities for their most loyal fans, offering exclusive perks and early access to new products.",
              },
              {
                id: 3,
                data: "Affiliate Marketing: Partnering with brands to promote their products and earn commissions on sales.",
              },
            ],
          },
        ],
      },
      {
        id: "global-inspiration",
        title: "Global Inspiration: Learning from the Best",
        img: ICONS?.globalInspiration,
        content: [
          {
            id: 1,
            list: "China: Douyin (TikTok's Chinese counterpart) has revolutionized creator commerce, with live streaming and social commerce deeply integrated into the platform.",
          },
          {
            id: 2,
            list: "United States: MrBeast, a renowned YouTuber, has successfully transitioned into the consumer goods space with his candy brand, Feastables, demonstrating the power of creator-led brands.",
          },
          {
            id: 3,
            listTitle: "The Future of Creator-Driven Commerce in India",
            list: "The Indian creator economy is poised for explosive growth. As digital adoption continues to rise, and as creators become increasingly sophisticated in their business strategies, we can expect to see:",
            listData: [
              {
                id: 1,
                data: "The emergence of new creator-led brands: More creators will launch their own products and build successful businesses around their personal brands.",
              },
              {
                id: 2,
                data: "Greater integration of technology: AI, AR/VR, and blockchain will play an increasingly important role in enhancing the creator experience and driving sales.",
              },
              {
                id: 3,
                data: "Increased focus on community building: Creators will prioritize building strong, loyal communities around their brands, fostering deeper connections with their audience.",
              },
            ],
          },
        ],
      },
      {
        id: "leveraging",
        title: "Leveraging the Shift:",
        img: ICONS?.leveraging,
        content: [
          {
            id: 1,
            list: "Businesses can leverage this shift by:",
            listData: [
              {
                id: 1,
                data: "Partnering with relevant creators: Collaborating with creators who align with their brand values and target audience.",
              },
              {
                id: 2,
                data: "Developing creator-specific marketing programs: Offering exclusive deals, rewards, and incentives to creators.",
              },
              {
                id: 3,
                data: "Investing in creator education and support: Providing resources and tools to help creators build sustainable businesses.",
              },
            ],
          },
          {
            id: 2,
            list: "The rise of creator-driven commerce is not just a trend; it's a fundamental shift in how businesses connect with consumers. By embracing this new reality, businesses can unlock new opportunities for growth and build lasting relationships with their audience.",
          },
          {
            id: 3,
            disclaimer: true,
            list: "This blog post is for informational purposes only and should not be construed as financial or investment advice.",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    img: ICONS?.theRealKelly,
    routeTitle: "Your Digital Agency's Survival Guide",
    title: "The AI Tsunami : Your Digital Agency's Survival Guide",
    button: "Read now",
    date: "November 2, 2023",
  },
  {
    id: 4,
    img: ICONS?.blogTsunami,
    routeTitle: "Your Digital Agency's Survival Guide",
    title: "The AI Tsunami : Your Digital Agency's Survival Guide",
    button: "Read now",
    date: "November 2, 2023",
  },
  {
    id: 5,
    img: ICONS?.topCreators,
    routeTitle: "Your Digital Agency's Survival Guide",
    title: "The AI Tsunami : Your Digital Agency's Survival Guide",
    button: "Read now",
    date: "November 2, 2023",
  },
  {
    id: 6,
    img: ICONS?.theRealKelly,
    routeTitle: "Your Digital Agency's Survival Guide",
    title: "The AI Tsunami : Your Digital Agency's Survival Guide",
    button: "Read now",
    date: "November 2, 2023",
  },
  // {
  //   id: 7,
  //   img: ICONS?.blogTsunami,
  //   title: "The AI Tsunami : Your Digital Agency's Survival Guide",
  //   button: "Read now",
  //   date: "November 2, 2023",
  // },
  // {
  //   id: 8,
  //   img: ICONS?.topCreators,
  //   title: "The AI Tsunami : Your Digital Agency's Survival Guide",
  //   button: "Read now",
  //   date: "November 2, 2023",
  // },
  // {
  //   id: 9,
  //   img: ICONS?.theRealKelly,
  //   title: "The AI Tsunami : Your Digital Agency's Survival Guide",
  //   button: "Read now",
  //   date: "November 2, 2023",
  // },
  // {
  //   id: 10,
  //   img: ICONS?.blogTsunami,
  //   title: "The AI Tsunami : Your Digital Agency's Survival Guide",
  //   button: "Read now",
  //   date: "November 2, 2023",
  // },
  // {
  //   id: 11,
  //   img: ICONS?.topCreators,
  //   title: "The AI Tsunami : Your Digital Agency's Survival Guide",
  //   button: "Read now",
  //   date: "November 2, 2023",
  // },
  // {
  //   id: 12,
  //   img: ICONS?.theRealKelly,
  //   title: "The AI Tsunami : Your Digital Agency's Survival Guide",
  //   button: "Read now",
  //   date: "November 2, 2023",
  // },
  // {
  //   id: 13,
  //   img: ICONS?.blogTsunami,
  //   title: "The AI Tsunami : Your Digital Agency's Survival Guide",
  //   button: "Read now",
  //   date: "November 2, 2023",
  // },
  // {
  //   id: 14,
  //   img: ICONS?.topCreators,
  //   title: "The AI Tsunami : Your Digital Agency's Survival Guide",
  //   button: "Read now",
  //   date: "November 2, 2023",
  // },
  // {
  //   id: 15,
  //   img: ICONS?.theRealKelly,
  //   title: "The AI Tsunami : Your Digital Agency's Survival Guide",
  //   button: "Read now",
  //   date: "November 2, 2023",
  // },
];

export const socialMedia = [
  {
    id: 1,
    img: ICONS?.instagram,
    link: "https://www.instagram.com/gainwix/",
  },
  {
    id: 2,
    img: ICONS?.facebook,
    link: "https://www.facebook.com/people/GainWix/61556584534406/",
  },
  {
    id: 3,
    img: ICONS?.linkedin,
    link: "https://www.linkedin.com/company/gainwixai/",
  },

  {
    id: 4,
    img: ICONS?.twitter,
    link: "https://x.com/i/flow/login?redirect_after_login=%2Fgainwixai%2F",
  },
];

export const tsunamicBlog = [
  {
    id: 1,
    img: ICONS?.blogTsunami,
    title: "The AI Tsunami : Your Digital Agency's Survival Guide",
    button: "Read now",
    date: "November 2, 2023",
  },
  {
    id: 2,
    img: ICONS?.blogTsunami,
    title: "The AI Tsunami : Your Digital Agency's Survival Guide",
    button: "Read now",
    date: "November 2, 2023",
  },
  {
    id: 3,
    img: ICONS?.blogTsunami,
    title: "The AI Tsunami : Your Digital Agency's Survival Guide",
    button: "Read now",
    date: "November 2, 2023",
  },
];
