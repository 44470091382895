import React, { useState } from "react";
import GetStartedButton from "../button/GetStartedButton";

const FooterData = ({ content, blogs }) => {
  const [data, setData] = useState("");

  const handleSubmit = (e) => {
    const url =
      "https://script.google.com/macros/s/AKfycbyTauozRiew4Pewbjao4ba8pegSnpSAShZhYJCgE73S4Ya37icxv-e5Dggm6hUVhJ6F/exec";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `Email=${data}`,
    })
      .then((res) => res.text())
      .then((data) => {
        alert(data);
      })
      .catch((error) => console.log(error));
  };


  return (
    <div className="bg-[#acff00] md:min-h-[80vh] flex justify-center items-center xs:py-10">
      <div className="max-w-[70em] m-auto text-center">
        <h2 className="md:text-[3.5vw] font-[700] tracking-tight mb-4 md:leading-[4.5vw] s:leading-[5.5vw]">
          {content}
        </h2>
        {blogs ? (
          <div className="sm:flex sm:gap-5 w-[70%] m-auto md:mt-10 xs:mt-5">
            <input
              placeholder="Enter your email id"
              value={data}
              className="rounded-full border-2 border-black bg-[#acff00] outline-none pl-10 py-3 w-full text-black placeholder:text-black placeholder:font-[600] md:text-[1vw] font-[600]"
              onChange={(e) => setData(e.target.value)}
            />
            <button
              className="bg-black text-white rounded-full py-3 px-10 md:text-[1.6vw] tracking-tighter  font-[700] xs:mt-5 md:mt-0"
              onClick={handleSubmit}
            >
              Subscribe
            </button>
          </div>
        ) : (
          <GetStartedButton bg />
        )}
      </div>
    </div>
  );
};

export default FooterData;
