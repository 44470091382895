import React from "react";

const GetStartedButton = ({ widthFull, bg }) => {
  return (
    <div>
      <button
        className={`${bg && "bg-black text-white" } bg-[#acff00] text-[#0f1013] md:text-[1.6vw] tracking-tighter  font-[700] rounded-xl py-4 hover:scale-105 ${
          widthFull ? "w-full" : "px-10"
        }`}
      >
        Get Started - For Free!
      </button>
    </div>
  );
};

export default GetStartedButton;
