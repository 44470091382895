import React from "react";
import { buttons, tools } from "../constants/home";
import { ICONS } from "../../../assets/icons";

const Tools = () => {
  return (
    <div className="campare-grid w-[80%] m-auto md:flex justify-between md:mt-14">
      <div
        className="rounded-2xl xs:p-5 md:p-10 md:w-[40vw] border relative md:left-[60px] overflow-hidden"
        style={{
          ...(window.innerWidth >= 992 && {
            transform:
              "translate3d(-60px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-3deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }),
          boxShadow:
            "0 0 #8a8a8a00, 0 0 #8a8a8a08, 0 1px .2px #8a8a8a0a, 0 1px .7px #8a8a8a0b, 0 2px 1.9px #8a8a8a0c, 0 5px 4.7px #8a8a8a0c, 0 11px 11.1px #8a8a8a0d, 0 26px 27.3px #8a8a8a0e",
        }}
      >
        <div>
          <div className="grid grid-cols-3 gap-4 border-b-2 pb-5">
            {tools?.map((item) => (
              <div
                className="flex flex-col justify-center items-center"
                key={item?.id}
              >
                <img src={item?.img} alt="link" className="rounded-xl" />
                <p className="text-[#0f1013] font-[700] xs:text-[3vw] s:text-[1.5vw]">
                  {item?.name}
                </p>
                <p className="text-[#777c8a] xs:text-[2.5vw] s:text-[1vw]">{item?.btnText}</p>
                <p className="text-[#0f1013] xs:text-[3vw] font-[700] s:text-[1.5vw]">
                  {item?.amount}
                </p>
              </div>
            ))}
          </div>
          <div className="text-center pt-5">
            <h2 className="text-[#0f1013] font-[700] md:text-[2vw]">
              All these = $144 per month
            </h2>
            <p className="font-[700] md:text-[1vw] text-[#777c8a]">
              Save big and ditch your other tools
            </p>
          </div>
        </div>
      </div>

      <div className="xs:hidden md:flex md:justify-start md:items-start">
        <h2 className="vs-text p-5 rounded-full text-[1.5vw] text-[#777c8a]">
          VS
        </h2>
      </div>
      <div
        className="rounded-2xl xs:p-5 md:p-10 md:w-[40vw] border relative md:-left-[60px] overflow-hidden bg-black"
        // style={{
        //   transform:
        //     "translate3d(60px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(3deg) skew(0deg, 0deg)",
        //   transformStyle: "preserve-3d",
        //   boxShadow:
        //     "0 0 #8a8a8a00, 0 0 #8a8a8a08, 0 1px .2px #8a8a8a0a, 0 1px .7px #8a8a8a0b, 0 2px 1.9px #8a8a8a0c, 0 5px 4.7px #8a8a8a0c, 0 11px 11.1px #8a8a8a0d, 0 26px 27.3px #8a8a8a0e",
        // }}
        style={{
          boxShadow:
            "0 0 #8a8a8a00, 0 0 #8a8a8a08, 0 1px .2px #8a8a8a0a, 0 1px .7px #8a8a8a0b, 0 2px 1.9px #8a8a8a0c, 0 5px 4.7px #8a8a8a0c, 0 11px 11.1px #8a8a8a0d, 0 26px 27.3px #8a8a8a0e",
          ...(window.innerWidth >= 992 && {
            transform:
              "translate3d(60px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(3deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }),
        }}
      >
        <div>
          <div>
            <div className="w-full flex justify-center items-center mb-6">
              <img src={ICONS?.logoGain} alt="logo" className="" />
            </div>

            <div className="grid grid-cols-3 gap-4">
              {buttons?.slice(0, 6).map((data) => (
                <button
                  key={data?.id}
                  className="xs:text-[2.5vw] md:text-[1.2vw] font-[700] rounded-3xl bg-white py-2 tracking-tight"
                  style={{
                    boxShadow:
                      "0 0 #0000, 0 1px .4px #00000008, 0 2px .8px #0000000a, 0 3px 1.6px #0000000b, 0 5px 2.9px #0000000c, 0 9px 5.3px #0000000c, 0 15px 10.4px #0000000d, 0 31px 22.8px #0000000e",
                  }}
                >
                  {data?.btn}
                </button>
              ))}
            </div>

            {buttons?.length > 6 && (
              <div className="grid grid-cols-2 gap-4  pb-5 mt-4 md:px-10">
                {buttons?.slice(6).map((data) => (
                  <button
                    key={data?.id}
                    className="xs:text-[2.5vw] md:text-[1.2vw] font-[700] rounded-3xl bg-white py-2 tracking-tight"
                    style={{
                      boxShadow:
                        "0 0 #0000, 0 1px .4px #00000008, 0 2px .8px #0000000a, 0 3px 1.6px #0000000b, 0 5px 2.9px #0000000c, 0 9px 5.3px #0000000c, 0 15px 10.4px #0000000d, 0 31px 22.8px #0000000e",
                    }}
                  >
                    {data?.btn}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="text-center pt-5">
            <h2 className="text-white font-[700] md:text-[2vw]">Free!</h2>
            <p className="font-[700] md:text-[1vw] text-[#777c8a]">
              One account gives you everything
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
