import React from "react";
import { creators } from "../constants/home";

const Creators = () => {
  return (
    <div className="flex gap-3 md:my-20 marquee xs:my-5">
      {creators?.map((data) => (
        <div
          key={data?.id}
          className="border-[3px] border-[#e1e5f0] rounded-xl p-6 flex flex-col justify-between h-[40vh] xs:w-[20%] md:!w-[26vw] flex-shrink-0"
        >
          <div>
            <img
              src={data?.img}
              alt="img"
              className="w-20 h-20 rounded-full my-4"
            />
            <p className="text-[#1e2027] md:text-[1.2vw]">{data?.content}</p>
          </div>
          <div>
            <h2 className="font-[700] md:text-[1.5vw]">{data?.name}</h2>
            <p className="text-[#777c8a]">{data?.role}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Creators;
