import React, { useState } from "react";
import { ICONS } from "../../assets/icons";
import { Link } from "react-router-dom";
import { Popover } from "antd";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const [productsVisibleRes, setProductsVisibleRes] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleopen = () => {
    setMobileNav(!mobileNav);
  };

  const handleProductsRes = () => {
    setProductsVisibleRes(!productsVisibleRes);
  };

  const content = (
    <div className="flex flex-col px-2">
      <h2 className="text-[#979797] font-[600] text-[1vw]">Our Product</h2>
      <a
        href="https://gainwix.ai/"
        target="_blank"
        rel="noopener noreferrer"
        className="!no-underline !text-inherit"
      >
        <div className="flex gap-3 items-center cursor-pointer">
          <img
            src={ICONS?.logo}
            alt="logo"
            className="bg-[#F1F2FF] h-full p-3 rounded-md w-10"
          />
          <div className="py-2">
            <h2 className="font-[600] text-[1.25vw]">Gainwix.ai</h2>
            <p className="text-[#979797] font-[500]">
              Converstaional Relationship platform
            </p>
          </div>
        </div>
      </a>
    </div>
  );

  return (
    <>
      {/* <div className="flex flex-row items-center bg-[#fdffda] justify-center p-4">
        <img src={ICONS?.diamond} alt="diamond" className="w-[1.5vw]" />
        <h2 className="font-[400] text-[1vw] text-[#0f1013] px-2">
          <span className="font-[700]">New features</span> just launched to
          <span className="font-[700] pl-1">maximize your sales</span> on
          Gainwix! <span className="font-[700] underline">Explore them</span>
        </h2>
        <img src={ICONS?.diamond} alt="diamond" className="w-[1.5vw]" />
      </div> */}
      <div
        style={{ boxShadow: "2px 2px 22px 0px #0000000F" }}
        className="w-full m-auto py-7 md:px-10 xs:hidden md:block"
      >
        <div className="flex items-center justify-between md:text-[1.3vw] font-[600]">
          <Link to="/" className="flex items-center cursor-pointer">
            <img src={ICONS?.logoFull} alt="logo" />
            {/* <p className="text-[1.5vw] pl-2 tracking-tighter">GainWix</p> */}
          </Link>

          <div className="flex gap-10 items-center">
            <Popover
              content={content}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              placement="bottom"
              arrow={false}
            >
              <Link
                to="#"
                className="flex items-center gap-2 rounded-xl p-2 hover:bg-[#F1F2FF]"
                onClick={(e) => e.preventDefault()}
              >
                <p className="cursor-pointer">Product</p>
                <img src={ICONS?.downArrow} alt="down" />
              </Link>
            </Popover>
            <Link
              to="/blogs"
              className="cursor-pointer hover:bg-[#F1F2FF] rounded-xl p-2"
            >
              Blog
            </Link>
            <p className="cursor-pointer bg-[#acff00] rounded-xl p-2 hover:text-[#acff00] hover:bg-black">
              Contact us
            </p>
          </div>
        </div>
      </div>

      <div
        className="px-5 py-4 md:hidden"
        style={{ boxShadow: "2px 2px 22px 0px #0000000F" }}
      >
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center cursor-pointer">
            <img src={ICONS?.logo} alt="logo" />
            <p className="text-[1rem] pl-2 tracking-tighter font-[600]">
              GainWix
            </p>
          </Link>
          <div>
            {mobileNav ? (
              <img src={ICONS?.closeIcon} alt="icon" onClick={handleopen} />
            ) : (
              <img src={ICONS?.navBarLine} alt="icon" onClick={handleopen} />
            )}
          </div>
        </div>

        {mobileNav && (
          <div className="">
            <div className="font-[600]">
              <div className="border-b-[1px] w-full flex flex-col items-center justify-center pointer gap-4 p-4">
                <div className="flex gap-4">
                  <p className="text-[1.093rem]">Products</p>

                  <img
                    src={ICONS.downArrow}
                    alt="left"
                    className={`cursor-pointer ${
                      productsVisibleRes && "rotate-180"
                    }`}
                    onClick={handleProductsRes}
                  />
                </div>

                {productsVisibleRes && (
                  <div className="flex flex-col">
                    <h2 className="text-[#979797] font-[600] text-[1rem]">
                      Our Product
                    </h2>
                    <div className="flex gap-3 items-center">
                      <img
                        src={ICONS?.logo}
                        alt="logo"
                        className="bg-[#F1F2FF] h-full p-3 rounded-md w-10"
                      />
                      <div className="py-2">
                        <h2 className="font-[600]">Gainwix.ai</h2>
                        <p className="text-[#979797] font-[500]">
                          Converstaional Relationship platform
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <Link
                to="/blogs"
                onClick={() => setMobileNav(false)}
                className="border-b-[1px] w-full flex items-center justify-center pointer p-4"
              >
                Blog
              </Link>
              <p className="cursor-pointer bg-[#acff00] rounded-xl p-2 hover:text-[#acff00] hover:bg-black flex items-center justify-center pointer mt-5">
                Contact us
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
