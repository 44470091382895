import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../modules/home/Home";
import Navbar from "../components/navbar/Navbar";
import Blogs from "../modules/blogs/Blogs";
import Footer from "../components/footer/Footer";
import BlogData from "../modules/blogs/components/BlogData";
import "../App.css";
import { blogContent } from "../modules/blogs/constants/blogs";

function Index() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route
          path="/blogs/:routeTitle"
          element={<BlogData selectedItems={blogContent} />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default Index;
