import { ICONS } from "../../../assets/icons";

export const homeImageData = [
  {
    id: 1,
    img: ICONS?.homeBio,
    btnText: "Link-in-bio",
    bgColor: "linear-gradient(#95cbff, #fedbff)",
  },
  {
    id: 2,
    img: ICONS?.homeMediaPng,
    btnText: "Media Kit",
    bgColor: "linear-gradient(#cecaff, #f7d3d7)",
  },
  {
    id: 3,
    img: ICONS?.onlineStore,
    btnText: "Email Marketing",
    bgColor: "linear-gradient(#ffecbc, #ffcabc)",
  },
  {
    id: 4,
    img: ICONS?.homeMarketing,
    btnText: "Online store",
    bgColor: "linear-gradient(#c9ffdb, #f9fdc1)",
  },
  {
    id: 5,
    img: ICONS?.homeMarketing,
    btnText: "Online store",
    bgColor: "linear-gradient(#c9ffdb, #f9fdc1)",
  },
];

export const homeData = [
  {
    id: 1,
    img: ICONS?.image1,
  },
  {
    id: 2,
    img: ICONS?.image2,
  },
  {
    id: 3,
    img: ICONS?.image3,
  },
  {
    id: 4,
    img: ICONS?.image4,
  },
  {
    id: 5,
    img: ICONS?.image5,
  },
  {
    id: 6,
    img: ICONS?.image6,
  },
  {
    id: 7,
    img: ICONS?.image7,
  },
  {
    id: 8,
    img: ICONS?.image8,
  },
  {
    id: 9,
    img: ICONS?.image9,
  },
  {
    id: 10,
    img: ICONS?.image10,
  },
  {
    id: 11,
    img: ICONS?.image11,
  },
  {
    id: 12,
    img: ICONS?.image12,
  },
  {
    id: 13,
    img: ICONS?.image13,
  },
  {
    id: 14,
    img: ICONS?.image14,
  },
  {
    id: 15,
    img: ICONS?.image15,
  },
  {
    id: 16,
    img: ICONS?.image16,
  },
  {
    id: 17,
    img: ICONS?.image17,
  },
  {
    id: 18,
    img: ICONS?.image18,
  },
  {
    id: 19,
    img: ICONS?.image19,
  },
  {
    id: 20,
    img: ICONS?.image20,
  },
  {
    id: 21,
    img: ICONS?.image21,
  },
  {
    id: 22,
    img: ICONS?.image22,
  },
  {
    id: 23,
    img: ICONS?.image23,
  },
  {
    id: 24,
    img: ICONS?.image24,
  },
  {
    id: 25,
    img: ICONS?.image25,
  },
  {
    id: 26,
    img: ICONS?.image26,
  },
  {
    id: 27,
    img: ICONS?.image27,
  },
  {
    id: 28,
    img: ICONS?.image28,
  },
  {
    id: 29,
    img: ICONS?.image29,
  },
  {
    id: 30,
    img: ICONS?.image30,
  },
];

export const slideImages = [
  ICONS.slideImage1,
  ICONS.slideImage2,
  ICONS.slideImage3,
  ICONS.slideImage4,
  ICONS.slideImage5,
  ICONS.slideImage6,
  ICONS.slideImage7,
];

export const homeContainer = [
  {
    id: 1,
    img: ICONS?.onlineStore,
    bgColor: "linear-gradient(#ffecbc, #ffcabc)",
    btnText: "Email marketing",
    title: "Create and send",
    brkTitle: "emails to fans",
    para: "Build a complete email strategy from your Link-in-Bio, no experience needed. Do it at a fraction of the cost of other tools.",
  },
  {
    id: 2,
    img: ICONS?.homeMarketing,
    bgColor: "linear-gradient(#c9ffdb, #f9fdc1)",
    btnText: "Online store",
    title: "Sell more from",
    brkTitle: "your creator store",
    para: "Passive income unlocked. Sell digital products, courses, memberships, appointments and more—in minutes.",
  },
  {
    id: 3,
    img: ICONS?.homeMediaPng,
    bgColor: "linear-gradient(#cecaff, #f7d3d7)",
    btnText: "Media kit",
    title: "Make brand",
    brkTitle: "deals happen",
    para: "Send better, faster pitches with an auto-updating media kit. Show brands where to spend money: on you.",
  },
  {
    id: 4,
    img: ICONS?.homeInvoice,
    bgColor: "linear-gradient(#fadfcf, #96cbff)",
    btnText: "Invoicing",
    title: "Create invoices and get paid on time",
  },
  {
    id: 5,
    img: ICONS?.homeBrand,
    bgColor: "linear-gradient(#fadfcf, #96cbff)",
    btnText: "AI brand outreach",
    title: "Generate emails for faster pitching",
  },
  {
    id: 6,
    img: ICONS?.incomeDashboard,
    bgColor: "linear-gradient(#fadfcf, #96cbff)",
    btnText: "Income dashboard",
    title: "Sync and track your creator income",
  },
  {
    id: 7,
    img: ICONS?.otherCreator,
    title: "Other creator apps",
    bgColor: "linear-gradient(#fadfcf, #96cbff)",
    para: "Audience Manager, Link Shortener, Post Activity, Pricing Calculator, W-9 Generator, Early Pay",
  },
];

export const allInOneData = [
  {
    id: 1,
    img: ICONS?.saveMoney,
    title: "Save money",
    text: "No need to pay for multiple subscriptions",
  },
  {
    id: 2,
    img: ICONS?.syncData,
    title: "Sync data",
    text: "Automatically sync data across tools for better insights",
  },
  {
    id: 3,
    img: ICONS?.account,
    title: "One account for everything",
    text: "Access all of your creator tools from one place.",
  },
  {
    id: 4,
    img: ICONS?.tools,
    title: "No need to learn different tools",
    text: "Tools that are easy to use on their own and together.",
  },
  {
    id: 5,
    img: ICONS?.integration,
    title: "No integration headaches",
    text: "And no more wasted time trying to string together lots of tools",
  },
  {
    id: 6,
    img: ICONS?.cohesive,
    title: "One cohesive brand",
    text: "Find tools, resources, and community for creators all on GainWix",
  },
];

export const creators = [
  {
    id: 1,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 2,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 3,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 4,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 5,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 6,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 7,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 8,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 9,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 10,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 11,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 12,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 13,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 14,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
  {
    id: 15,
    img: ICONS?.packy,
    name: "hgy",
    role: "nmbhj",
    content:
      '"I love how much AI is integrated—whether it’s for product descriptions or email subject lines, the AI features make my life so much easier."',
  },
];

export const tools = [
  {
    id: 1,
    img: ICONS?.linkTree,
    name: "Linktree",
    btnText: "Link in bio",
    amount: "$8/mo",
  },
  {
    id: 2,
    img: ICONS?.mailChimp,
    name: "Mailchimp",
    btnText: "Email Marketing",
    amount: "$29/mo",
  },
  {
    id: 3,
    img: ICONS?.gumroad,
    name: "Gumroad",
    btnText: "Online Store",
    amount: "$39/mo",
  },
  {
    id: 4,
    img: ICONS?.canva,
    name: "Canva",
    btnText: "Media kits",
    amount: "$29/mo",
  },
  {
    id: 5,
    img: ICONS?.calendly,
    name: "Calendly",
    btnText: "Appointments",
    amount: "$10/mo",
  },
  {
    id: 6,
    img: ICONS?.bitly,
    name: "Bitly",
    btnText: "Link Shortener",
    amount: "$29/mo",
  },
];

export const buttons = [
  {
    id: 1,
    btn: "WhatsApp Automation",
  },
  {
    id: 2,
    btn: "Sequence Automation",
  },
  {
    id: 3,
    btn: "WhatsApp Store",
  },
  {
    id: 4,
    btn: "Contact Management",
  },
  {
    id: 5,
    btn: "Campaign Analytics",
  },
  {
    id: 6,
    btn: "ROI Calculator",
  },
  {
    id: 7,
    btn: "Unified Inbox",
  },
  {
    id: 8,
    btn: "Team Collaboration",
  },
  {
    id: 9,
    btn: "Multi Account Management",
  },
  {
    id: 10,
    btn: "Agency View",
  },
];
