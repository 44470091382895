import { useRef } from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import { ICONS } from "../../../assets/icons";

export default function ScrollZoom() {
  const container = useRef(null);

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 2]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);
  const opacityText = useTransform(scrollYProgress, [0, 1], [0, 1]);

  return (
    <div className="relative h-[400vh] mt-20" ref={container}>
      <div className="sticky top-0 h-[100vh] overflow-hidden bg-black">
        <motion.div
          className="absolute inset-0 flex items-center justify-center z-10 pointer-events-none"
          style={{
            opacity: opacityText,
          }}
          transition={{ type: "spring", stiffness: 100, damping: 20 }}
        >
          {/* <div className="text-white flex items-center font-bold text-[2.5vw] border rounded-full py-3 px-6">
            <img src={ICONS?.logo} alt="logo" className="pr-4" />
            Made with Gainwix.com
          </div> */}
          <div className="card example-5">
            <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
              <rect
                rx="50"
                ry="50"
                class="line"
                height="100%"
                width="100%"
                stroke-linejoin="round"
              />
            </svg>

            <div className="text-white flex items-center font-bold text-[2.5vw] py-3 px-6 border-4 rounded-full border-[#ffffff1a]">
              <img src={ICONS?.logo} alt="logo" className="pr-4" />
              Made with Gainwix.com
            </div>
          </div>
        </motion.div>

        <motion.div
          className="absolute inset-0"
          style={{
            scale: scale,
            opacity: opacity,
          }}
          transition={{ type: "spring", stiffness: 100, damping: 20 }}
        >
          <img
            src={ICONS?.homeZoomImage}
            alt="zoom"
            className="w-full h-full object-cover"
          />
        </motion.div>
      </div>
    </div>
  );
}
