import React from "react";
import GetStartedButton from "../../components/button/GetStartedButton";
import { homeContainer, homeData, homeImageData } from "./constants/home";
import SlideContent from "./components/SlideContent";
import AllinOne from "./components/AllinOne";
import Creators from "./components/Creators";
import Tools from "./components/Tools";
import PoweredByAi from "./components/poweredByAi/PoweredByAi";
import ScrollZoom from "./components/ScrollZoom";
import FooterData from "../../components/footer/FooterData";

const Home = () => {
  return (
    <>
      <div className="w-full xs:flex xs:flex-col md:flex md:flex-row flex-1 justify-between md:px-10 items-center md:gap-[14vw] xs:gap-[4vw] xs:px-5 mt-10">
        <div className="">
          <h2 className="text-[#0f1013] font-[700] text-[4.94vw] leading-[100%] md:text-left tracking-tighter xs:text-center">
            <span className="text-[#c7cbd8]">Gainwix.com</span> <br /> Where
            brands and creators come to make it.
          </h2>
        </div>
        <div className="text-left">
          <h2 className="text-[#1e2027] font-[700] text-left md:text-[1.8vw] tracking-tight">
            The All-in-one Creator Platform.
          </h2>
          <p className="text-[#777c8a] md:text-[1.3vw] md:pt-4 md:pb-5 xs:pt-2 xs:pb-3">
            Boost your WhatsApp store with conversational strategies, connecting
            creators and brands for meaningful collaborations—all in one place
          </p>
          <GetStartedButton widthFull />
        </div>
      </div>

      <div className="md:flex md:px-10 gap-2 my-10 flex-1 xs:px-5">
        <div
          key={homeImageData[0].id}
          className="bg-[#ecedf3] rounded-xl px-5 pt-5"
          style={{ backgroundImage: homeImageData[0].bgColor }}
        >
          <button
            className="md:text-[1.2vw] font-[700] rounded-3xl bg-white px-3 py-2 flex justify-start tracking-tight"
            style={{
              boxShadow:
                "0 0 #0000, 0 1px .4px #00000008, 0 2px .8px #0000000a, 0 3px 1.6px #0000000b, 0 5px 2.9px #0000000c, 0 9px 5.3px #0000000c, 0 15px 10.4px #0000000d, 0 31px 22.8px #0000000e",
            }}
          >
            {homeImageData[0].btnText}
          </button>
          <img src={homeImageData[0].img} alt="home" className="" />
        </div>

        <div className="xs:grid s:grid-cols-2 gap-2 h-auto xs:grid-cols-1 xs:mt-2 md:mt-0">
          {homeImageData.slice(1).map((data) => (
            <div key={data.id}>
              <div
                className="bg-[#ecedf3] rounded-xl px-5 pt-5"
                style={{ backgroundImage: data.bgColor }}
              >
                <button
                  className="md:text-[1.2vw] font-[700] rounded-3xl bg-white px-3 py-2 flex justify-start tracking-tight"
                  style={{
                    boxShadow:
                      "0 0 #0000, 0 1px .4px #00000008, 0 2px .8px #0000000a, 0 3px 1.6px #0000000b, 0 5px 2.9px #0000000c, 0 9px 5.3px #0000000c, 0 15px 10.4px #0000000d, 0 31px 22.8px #0000000e",
                  }}
                >
                  {data.btnText}
                </button>
                <img
                  src={data.img}
                  alt="home"
                  className="md:w-[50vw] h-[30vh] xs:mt-5 md:mt-0"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div class="flex pt-5 w-full overflow-hidden">
        <div class="flex items-center space-x-2 marquee">
          {homeData?.map((data) => (
            <div key={data?.id} className="flex-shrink-0">
              <img
                src={data?.img}
                alt="img"
                class="w-full h-[14vh] rounded-2xl"
              />
              <p>wehg</p>
            </div>
          ))}
        </div>
      </div>

      <div className="md:w-[60%] m-auto flex flex-col justify-center md:py-28 text-center xs:py-10 xs:px-5 md:px-0">
        <h2 className="text-[#0f1013] font-[700] md:text-[4.75vw] md:leading-[100%] tracking-tight text-center xs:text-[6vw]">
          <span className="text-[#c7cbd8]">Not just a Whatsapp Automation</span>{" "}
          <br />
          It's a full Conversational Relationship Platform
        </h2>
        <p className="text-[#777c8a] md:text-[1.5vw] md:pt-4 md:pb-5 xs:pt-2 xs:pb-3">
          Build your business effortlessly with our AI-powered platform—no code
          or design team required. Manage everything from one place
        </p>
        <GetStartedButton />
      </div>

      <div className="">
        <SlideContent
          btnText="Link-in-bio"
          title="Next-generation"
          para="A fully customized Link-in-Bio to drive engagement. All of your links in one place, designed your way."
          brkTitle="Link-in-Bio"
          bgColor="linear-gradient(#95cbff, #fedbff)"
          animation
          row
        />
      </div>

      <div className="flex flex-col items-stretch">
        {/* First Row */}
        <div className="xs:px-5 s:px-10 s:flex s:flex-row gap-2 my-2 xs:flex xs:flex-col">
          {homeContainer?.slice(0, 2).map((item) => (
            <div key={item?.id} className=" flex-1 flex items-stretch">
              <SlideContent
                btnText={item?.btnText}
                title={item?.title}
                para={item?.para}
                brkTitle={item?.brkTitle}
                img={item?.img}
                bgColor={item?.bgColor}
                heightImage
                className="w-full h-full"
              />
            </div>
          ))}
        </div>

        {/* Second Row */}
        <div className="md:flex md:flex-row xs:px-5 md:px-10 gap-2 my-2 flex-1 xs:flex xs:flex-col">
          {/* Left Side - Single SlideContent */}
          <div className="flex-1">
            <SlideContent
              btnText={homeContainer[2]?.btnText}
              title={homeContainer[2]?.title}
              para={homeContainer[2]?.para}
              brkTitle={homeContainer[2]?.brkTitle}
              img={homeContainer[2]?.img}
              bgColor={homeContainer[2]?.bgColor}
              className="w-full h-full"
            />
          </div>

          {/* Right Side - Grid of SlideContent */}
          <div className="grid md:grid-cols-2 gap-2 flex-1 xs:grid-cols-1 xs:justify-center">
            {homeContainer.slice(3, 7).map((data) => (
              <div
                key={data.id}
                className="flex md:items-stretch xs:items-center xs:justify-center w-full"
              >
                <SlideContent
                  btnText={data?.btnText}
                  title={data?.title}
                  para={data?.para}
                  brkTitle={data?.brkTitle}
                  img={data?.img}
                  bgColor={data?.bgColor}
                  sizeReduce
                  className="w-full h-full"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="bg-black flex flex-col h-[100vh] justify-center items-center relative">
        <div
          className="text-white flex items-center font-[700] text-[2.5vw] border rounded-full py-3 px-6 relative z-10"
          style={{
            opacity: opacity, // Dynamic opacity
            transition: "opacity 0.5s ease-out", // Smooth fade-out
          }}
        >
          <img src={ICONS?.logo} alt="logo" className="pr-4" />
          Made with Gainwix.com
        </div>
        <img
          src={ICONS?.homeZoomImage}
          alt="zoom"
          className="absolute z-0 opacity-0"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div> */}

      <ScrollZoom />

      <div className="md:my-20">
        <h2 className="text-[#0f1013] font-[700] leading-tight tracking-tight md:text-[4.5vw] xs:text-[7vw] py-5 text-center">
          <span className="text-[#c7cbd8]">Gainwix.com</span> <br /> Save money.{" "}
          <br /> Ditch your other tools.
        </h2>

        <Tools />
      </div>

      <AllinOne />

      <PoweredByAi />

      <div>
        <h2 className="text-[#0f1013] font-[700] xs:text-[5.75vw] leading-tight text-center tracking-tighter md:w-[60%] m-auto xs:px-5 md:px-0 mt-20">
          <span className="text-[#c7cbd8]">Gainwix.com</span> <br /> We're
          helping millions of creators make it.
        </h2>
        <div className="flex gap-3 md:my-0 w-full overflow-hidden xs:my-5">
          <Creators />
        </div>
      </div>

      <FooterData content="The best time to start selling on GainWix was yesterday. The next best time is now." />
    </>
  );
};

export default Home;
