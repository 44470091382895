import React, { useEffect, useState } from "react";
import { ICONS } from "../../../assets/icons";
import { slideImages } from "../constants/home";

const SlideContent = ({
  btnText,
  title,
  para,
  animation,
  brkTitle,
  img,
  row,
  bgColor,
  sizeReduce,
  heightImage,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slideImages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`rounded-2xl overflow-hidden ${
        row
          ? "xs:flex xs:flex-col xs:items-center xs:justify-center md:flex md:flex-row md:items-center md:mx-10 md:pt-20 xs:mx-5"
          : "md:flex md:flex-col-reverse xs:flex xs:flex-col xs:items-center xs:justify-center"
      } ${sizeReduce ? "md:px-10 md:pt-10 xs:w-full" : "md:px-20 md:pt-20"}`}
      style={{ backgroundImage: bgColor }}
    >
      <div
        className={`${
          sizeReduce ? " xs:my-7 md:my-5" : "md:my-16 xs:my-5"
        } text-center`}
      >
        {btnText && (
          <button
            className="md:text-[1.2vw] font-[700] rounded-3xl bg-white px-3 py-2 tracking-tight"
            style={{
              boxShadow:
                "0 0 #0000, 0 1px .4px #00000008, 0 2px .8px #0000000a, 0 3px 1.6px #0000000b, 0 5px 2.9px #0000000c, 0 9px 5.3px #0000000c, 0 15px 10.4px #0000000d, 0 31px 22.8px #0000000e",
            }}
          >
            {btnText}
          </button>
        )}

        {title && (
          <h2
            className={`text-[#0f1013] font-[700] leading-tight tracking-tight ${
              sizeReduce
                ? "s:text-[3vw] md:text-[1.2vw] md:py-5 xs:py-3"
                : "s:text-[3.75vw] md:py-5 xs:py-3 xs:text-[7vw]"
            }`}
          >
            {title} <br /> {brkTitle}
          </h2>
        )}

        {para && (
          <p
            className={`${
              sizeReduce ? "md:text-[0.7vw]" : "md:text-[1.4vw] md:pb-9 xs:pb-5"
            } text-[#777c8a] xs:px-3 md:py-0`}
          >
            {para}
          </p>
        )}

        <button className="text-[#777c8a] font-[700] hover:text-black">
          LEARN MORE
        </button>
      </div>

      {animation ? (
        <div className="slider-container-lib">
          <img
            src={ICONS?.leftHomeImage}
            alt="home"
            className="xs:hidden md:block left-lib-blank w-[20vw] h-[45vh]"
          />
          <div className="slider-lib w-slider">
            <div className="mask w-slider-mask">
              <img src={slideImages[currentIndex]} alt="slideimage" />
            </div>
          </div>
          <img
            src={ICONS?.rightHomeImage}
            alt="home"
            className="xs:hidden md:block right-lib-blank w-[20vw]"
          />
        </div>
      ) : (
        <img
          src={img}
          alt="img"
          className={`${heightImage ? "md:h-[40vh]" : ""}`}
        />
      )}
    </div>
  );
};

export default SlideContent;
