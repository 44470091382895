import diamond from "../icons/home/diamond.svg";
import logoFull from "../icons/logo-full.svg";
import navBarLine from "../icons/navBarLine.svg";
import closeIcon from "../icons/close-icon.svg";
import homeBio from "../icons/home/home-bio.png";
import homeMedia from "../icons/home/media-home.svg";
import homeMediaPng from "../icons/home/media-home-png.png";
import homeMarketing from "../icons/home/marketing-home.png";
import onlineStore from "../icons/home/online-store.png";
import image1 from "../icons/home/image-1.png";
import image2 from "../icons/home/image-2.png";
import image3 from "../icons/home/image-3.png";
import image4 from "../icons/home/image-4.png";
import image5 from "../icons/home/image-5.png";
import image6 from "../icons/home/image-6.png";
import image7 from "../icons/home/image-7.png";
import image8 from "../icons/home/image-8.png";
import image9 from "../icons/home/image-9.png";
import image10 from "../icons/home/image-10.png";
import image11 from "../icons/home/image-11.png";
import image12 from "../icons/home/image-12.png";
import image13 from "../icons/home/image-13.png";
import image14 from "../icons/home/image-14.png";
import image15 from "../icons/home/image-15.png";
import image16 from "../icons/home/image-16.png";
import image17 from "../icons/home/image-17.png";
import image18 from "../icons/home/image-18.png";
import image19 from "../icons/home/image-19.png";
import image20 from "../icons/home/image-20.png";
import image21 from "../icons/home/image-21.png";
import image22 from "../icons/home/image-22.png";
import image23 from "../icons/home/image-23.png";
import image24 from "../icons/home/image-24.png";
import image25 from "../icons/home/image-25.png";
import image26 from "../icons/home/image-26.png";
import image27 from "../icons/home/image-27.png";
import image28 from "../icons/home/image-28.png";
import image29 from "../icons/home/image-29.png";
import image30 from "../icons/home/image-30.png";
import leftHomeImage from "../icons/home/left-home-image.png";
import rightHomeImage from "../icons/home/right-home-image.png";
import slideImage1 from "../icons/home/slide-image-1.png";
import slideImage2 from "../icons/home/slide-image-2.png";
import slideImage3 from "../icons/home/slide-image-3.png";
import slideImage4 from "../icons/home/slide-image-4.png";
import slideImage5 from "../icons/home/slide-image-5.png";
import slideImage6 from "../icons/home/slide-image-6.png";
import slideImage7 from "../icons/home/slide-image-7.png";
import homeInvoice from "../icons/home/invoicing.png";
import homeBrand from "../icons/home/brand.png";
import incomeDashboard from "../icons/home/income-dashboard.png";
import otherCreator from "../icons/home/other-creator.png";
import saveMoney from "../icons/home/save-money.svg";
import syncData from "../icons/home/sync.svg";
import account from "../icons/home/account.svg";
import tools from "../icons/home/tools.svg";
import integration from "../icons/home/no-integration.svg";
import cohesive from "../icons/home/cohesive-brand.svg";
import packy from "../icons/home/packy.png";
import linkTree from "../icons/home/linktree.png";
import mailChimp from "../icons/home/mailchimp.png";
import gumroad from "../icons/home/gumroad.png";
import canva from "../icons/home/canva.png";
import calendly from "../icons/home/calendly.png";
import bitly from "../icons/home/bitly.png";
import logoGain from "../icons/logo-gain.svg";
import homeZoomImage from "../icons/home/image-zoom.png";
import logo from "../icons/logo.png";
import downArrow from "../icons/down-arrow.svg";
import leftArrow from "../icons/left-arrow.svg";

//blog
import blogMain from "../icons/blogs/blog-main.png";
import blogTsunami from "../icons/blogs/blog-tsunami.svg";
import tsunami from "../icons/blogs/tsunamic-big.svg";
import avatar from "../icons/blogs/avatar.svg";
import instagram from "../icons/instagram.svg";
import facebook from "../icons/facebook.svg";
import linkedin from "../icons/linkedin.svg";
import twitter from "../icons/twitter.svg";
import glimmer from "../icons/blogs/glimmer.svg";
import tsunamiAgency from "../icons/blogs/tsunami-agency.svg";
import topCreators from "../icons/blogs/topCreators.svg";
import theRealKelly from "../icons/blogs/theRealKelly.svg";
import drivenCommerce from "../icons/blogs/driven-commerce.svg";
import drivenCommerceBlog from "../icons/blogs/drivenCommerceBlog.svg";
import drivenCommerceList from "../icons/blogs/drivenCommerceList.svg";
import monetization from "../icons/blogs/monetization.svg";
import globalInspiration from "../icons/blogs/globalInspiration.svg";
import leveraging from "../icons/blogs/leveraging.svg";

export const ICONS = {
  logoFull: logoFull,
  downArrow: downArrow,
  navBarLine: navBarLine,
  closeIcon: closeIcon,
  logo: logo,
  logoGain: logoGain,
  linkTree: linkTree,
  mailChimp: mailChimp,
  gumroad: gumroad,
  canva: canva,
  calendly: calendly,
  bitly: bitly,
  diamond: diamond,
  homeBio: homeBio,
  homeMedia: homeMedia,
  homeMediaPng: homeMediaPng,
  homeMarketing: homeMarketing,
  onlineStore: onlineStore,
  leftHomeImage: leftHomeImage,
  rightHomeImage: rightHomeImage,
  homeInvoice: homeInvoice,
  homeBrand: homeBrand,
  incomeDashboard: incomeDashboard,
  otherCreator: otherCreator,
  slideImage1: slideImage1,
  slideImage2: slideImage2,
  slideImage3: slideImage3,
  slideImage4: slideImage4,
  slideImage5: slideImage5,
  slideImage6: slideImage6,
  slideImage7: slideImage7,
  saveMoney: saveMoney,
  syncData: syncData,
  account: account,
  tools: tools,
  integration: integration,
  cohesive: cohesive,
  packy: packy,
  image1: image1,
  image2: image2,
  image3: image3,
  image4: image4,
  image5: image5,
  image6: image6,
  image7: image7,
  image8: image8,
  image9: image9,
  image10: image10,
  image11: image11,
  image12: image12,
  image13: image13,
  image14: image14,
  image15: image15,
  image16: image16,
  image17: image17,
  image18: image18,
  image19: image19,
  image20: image20,
  image21: image21,
  image22: image22,
  image23: image23,
  image24: image24,
  image25: image25,
  image26: image26,
  image27: image27,
  image28: image28,
  image29: image29,
  image30: image30,
  homeZoomImage: homeZoomImage,

  //blog
  blogMain: blogMain,
  blogTsunami: blogTsunami,
  tsunami: tsunami,
  avatar: avatar,
  instagram: instagram,
  facebook: facebook,
  linkedin: linkedin,
  twitter: twitter,
  glimmer: glimmer,
  tsunamiAgency: tsunamiAgency,
  leftArrow: leftArrow,
  topCreators: topCreators,
  theRealKelly: theRealKelly,
  drivenCommerce: drivenCommerce,
  drivenCommerceBlog: drivenCommerceBlog,
  drivenCommerceList: drivenCommerceList,
  monetization: monetization,
  globalInspiration: globalInspiration,
  leveraging: leveraging,
};
