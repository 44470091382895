import React from "react";
import "./PoweredByAi.css";

const PoweredByAi = () => {
  return (
    <section class="container-new ai larger clip">
      <div
        data-w-id="923d80de-18c0-6374-5ee7-fb0cacaeb5c9"
        class="ai-container-box"
      >
        <div class="ai-container">
          <div class="scanlines-section">
            <div class="grid-wrapper">
              <div class="grid-15">
                <div class="grid__mask"></div>
                <div class="grid__vertical">
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                  <div class="vertical-line"></div>
                </div>
                <div class="grid__horizontal-wrapper">
                  <div class="grid__horizontal">
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                  </div>
                  <div class="grid__horizontal">
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                  </div>
                  <div class="grid__horizontal">
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                  </div>
                  <div class="grid__horizontal">
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                    <div class="horizontal-line-2"></div>
                  </div>
                </div>
              </div>
              <div class="ai-container-text px-4">
                <div class="spline-embed w-embed w-iframe">
                  {/* <iframe
                      src="https://my.spline.design/beaconsbrainrotatesquarew200x200px-8109091b91c503fb4efee0589aaebf50/"
                      frameborder="0"
                      width="100%"
                      height="100%"
                    ></iframe> */}
                </div>
                <h2 class="section-heading-new white">Powered by AI</h2>
                <div class="section-paragraph white">
                  The most powerful creator tools in the game are now integrated
                  with AI features. Get custom insights to learn about and
                  activate your audience, from follower to subscriber to
                  customer.
                </div>
                <div class="section-paragraph aigenerate">
                  Generate with GainWix AI
                </div>
                <div class="ai-tools">
                  <div class="app-name-label-copy">Strategy AI</div>
                  <div class="app-name-label-copy">
                    AI Powered Advanced Segmentation
                  </div>
                  <div class="app-name-label-copy">AI Powered Automations</div>
                  <div class="app-name-label-copy">
                    Personalized Collaboration
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PoweredByAi;
