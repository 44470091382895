import React, { useState } from "react";
import { ICONS } from "../../assets/icons";
import { blogContent } from "./constants/blogs";
import FooterData from "../../components/footer/FooterData";

const Blogs = () => {
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogContent.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(blogContent.length / itemsPerPage);

  const getPageNumbers = () => {
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + 3, totalPages);

    if (endPage - startPage < 3) {
      startPage = Math.max(endPage - 3, 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  return (
    <div className="mt-24">
      <div className="w-full m-auto flex flex-col justify-center items-center xs:px-5 md:px-0">
        <h2 className="font-[700] md:text-[3.5vw] tracking-tighter xs:text-[30px]">
          GainWix Blog
        </h2>
        <img
          src={ICONS?.blogMain}
          alt="blog"
          className="sm:w-[50vw] md:mt-8 xs:mt-5"
        />
        <h2 className="font-[700] md:text-[2vw] tracking-tighter text-center md:my-8 xs:my-5 xs:text-[20px]">
          GainWix 2.0 : Putting the AI <br /> in GainWix AI
        </h2>

        <p className="border-[1px] border-[#F0F0F0] rounded-full p-2.5 font-[500] text-[#979797] md:text-[1.15vw] flex items-center tracking-tighter">
          Read our blogs
        </p>
      </div>

      <div className="xs:grid xs:grid-cols-1 sm:grid sm:grid-cols-2 md:grid md:grid-cols-3 md:gap-x-10 md:gap-y-4 md:w-[75%] m-auto mt-10">
        {currentItems?.map((data) => (
          <div
            key={data?.id}
            onClick={() => {
              if (data?.id === 1 || data?.id === 2) {
                window.parent.location = `/blogs/${data?.routeTitle}`;
              }
            }}
            className="border border-transparent hover:border-[#BCBCBC] p-3 rounded-3xl flex flex-col justify-between cursor-pointer h-full min-h-[320px]"
          >
            <div className="flex-grow flex flex-col">
              <img
                src={data?.img}
                alt="blogTsunami"
                className="w-full h-auto"
              />
              <h2 className="font-[700] xs:text-[17px] md:text-[1.25vw] tracking-tighter my-4">
                {data?.title}
              </h2>
            </div>
            <div className="font-[500] text-[#979797] md:text-[1.15vw] xs:text-[12px] flex items-center gap-6 tracking-tighter justify-between">
              <p className="border-[1px] border-[#F0F0F0] rounded-full p-2.5">
                {data?.button}
              </p>
              <p>{data?.date}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center items-center mt-8 space-x-1 mb-10">
        <button
          onClick={() => setCurrentPage((curr) => Math.max(curr - 1, 1))}
          className={`w-10 h-10 rounded-lg flex items-center justify-center text-gray-600 hover:bg-gray-200 `}
          style={{
            background:
              "linear-gradient(151.63deg, #D1EAFC 9.82%, #93B7D1 93.69%) ",
            opacity: currentPage > 1 ? "1" : "0.3",
            cursor: currentPage > 1 ? "pointer" : "not-allowed",
          }}
        >
          <img src={ICONS?.leftArrow} alt="left" />
        </button>

        {getPageNumbers().map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            style={{
              background:
                currentPage === page
                  ? "#acff00"
                  : "linear-gradient(151.63deg, #D1EAFC 9.82%, #93B7D1 93.69%)",
            }}
            className="w-10 h-10 rounded-lg flex items-center justify-center text-black font-[500]"
          >
            {page}
          </button>
        ))}

        <button
          onClick={() =>
            setCurrentPage((curr) => Math.min(curr + 1, totalPages))
          }
          className={`w-10 h-10 rounded-lg flex items-center justify-center text-gray-600 hover:bg-gray-200 `}
          style={{
            background:
              "linear-gradient(151.63deg, #D1EAFC 9.82%, #93B7D1 93.69%) ",
            opacity: currentPage < totalPages ? "1" : "0.3",
            cursor: currentPage < totalPages ? "pointer" : "not-allowed",
          }}
        >
          <img src={ICONS?.leftArrow} alt="left" className=" rotate-180" />
        </button>
      </div>

      <FooterData
        content="Subscribe to our Email Newsletter and Stay up to Date with Recent Trends and Advance Trends"
        blogs
      />
    </div>
  );
};

export default Blogs;
