import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ICONS } from "../../../assets/icons";
import { tsunamicBlog } from "../constants/blogs";
import FooterData from "../../../components/footer/FooterData";

const BlogData = ({ selectedItems }) => {
  const { routeTitle } = useParams();

  const item = selectedItems.find((item) => item.routeTitle === routeTitle);

  const [activeSection, setActiveSection] = useState(
    item?.blogContent?.[0]?.id
  );
  const sectionsRef = useRef({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.getAttribute("id"));
          }
        });
      },
      { threshold: 0.5 }
    );

    Object.values(sectionsRef.current).forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div>
        <div className="md:flex md:flex-row md:w-[66%] m-auto items-stretch md:gap-20 flex-1 mt-20 xs:px-5 xs:flex xs:flex-col xs:justify-center xs:items-center">
          <div className="flex flex-col justify-between min-h-full">
            <p className="font-[500] text-[#979797] md:text-[1.15vw] tracking-tighter xs:text-[20px]">
              {item?.date}
            </p>
            <h2 className="font-[700] md:text-[3vw] tracking-tighter md:leading-[3.5vw] mt-5 md:mb-10 xs:mb-5 xs:text-[25px]">
              {item?.title}
            </h2>

            <div className="flex items-center md:border rounded-full md:w-[14vw] p-3 font-[500] gap-3">
              <img src={ICONS?.avatar} alt="avatar" />
              <div>
                <h2 className="md:text-[1vw]">{item?.ceo}</h2>
                <p className="text-[#979797] md:text-[0.85vw]">{item?.role}</p>
              </div>
            </div>
          </div>

          <img
            src={item?.imgBlog}
            alt="blog"
            className="md:w-[35vw] h-auto object-cover flex-1 rounded-2xl xs:mt-5 md:mt-0"
          />
        </div>

        <div className="md:flex gap-20 md:w-[75%] md:m-auto md:my-28 xs:my-10 xs:mx-5">
          <div className="font-[500] md:w-[25vw]">
            {item?.blogContent?.map(({ id, title }, index) => (
              <p
                key={id}
                className={`text-[#CECDCD] md:text-[1.25vw] tracking-tighter pb-3 cursor-pointer ${
                  activeSection === id ? "!text-black" : ""
                } ${id !== "ai-tsunami" && "pt-7"} ${
                  index !== item?.blogContent.length - 1 ? "border-b-2" : ""
                }`}
                onClick={() => {
                  const section = sectionsRef.current[id];
                  if (section) {
                    section.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
              >
                {title}
              </p>
            ))}

            <div className="rounded-3xl bg-[#F0F0F0] w-full px-5 pb-5 pt-6 mt-10">
              <p className="md:text-[1.25vw] tracking-tighter text-black">
                Get Started with your Conversational Relationship Platform and
                Start Engaging with your Customers in Real Time
              </p>
              <button className="w-full bg-[#acff00] text-[#0f1013] md:text-[1.2vw] tracking-tighter  font-[700] rounded-xl py-4 hover:scale-105 mt-5">
                Get Started
              </button>
            </div>
          </div>

          <div className="md:w-[60vw] max-h-[90vh] overflow-hidden overflow-y-scroll">
            {item?.blogContent?.map((data) => (
              <div
                key={data?.id}
                id={data?.id}
                ref={(el) => (sectionsRef.current[data?.id] = el)}
                className="mb-10"
              >
                <div
                  className={`flex ${
                    data?.reverse ? "flex-col-reverse" : "flex-col"
                  }`}
                >
                  {data?.img && (
                    <img src={data?.img} alt="img" className="mb-5 h-auto" />
                  )}

                  <h2 className="font-[600] md:text-[2.5vw] tracking-tighter">
                    {data?.title}
                  </h2>
                </div>

                <div>
                  {data?.content?.map((item) => (
                    <div
                      key={item?.id}
                      className={`${
                        data?.id === "charting-course" && item?.id === 2
                          ? ""
                          : "mt-5"
                      }`}
                    >
                      {item?.listTitle && (
                        <p className="font-[600] md:text-[1.22vw] tracking-tighter leading-relaxed">
                          {item?.listTitle}
                        </p>
                      )}

                      <p
                        className={`text-[#979797] font-[500] md:text-[1.12vw] tracking-tighter leading-relaxed`}
                      >
                        {item?.disclaimer && (
                          <span className="font-[600] text-black pr-2">
                            Disclaimer:
                          </span>
                        )}

                        {item?.list}
                      </p>

                      {item?.list2 && (
                        <p
                          className={`text-[#979797] font-[500] md:text-[1.12vw] tracking-tighter leading-relaxed`}
                        >
                          {item?.list2}
                        </p>
                      )}

                      {item?.listData &&
                        item?.listData?.map((data) => (
                          <ul key={data?.id} className="list-disc ml-5">
                            <li className="text-[#979797] font-[500] md:text-[1.12vw] tracking-tighter leading-relaxed">
                              {data?.data}
                            </li>
                          </ul>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {/* <div>
          <h2 className="font-[800] md:text-[1vw] mb-7">Share</h2>
          <div className="xs:flex xs:flex-row md:flex md:flex-col xs:gap-6 md:gap-0">
            {socialMedia?.map((data) => (
              <img
                key={data?.id}
                src={data?.img}
                alt="img"
                className="mb-4 cursor-pointer"
              />
            ))}
          </div>
        </div> */}
        </div>

        <div className="md:w-[75%] m-auto text-center mb-28">
          <h2 className="font-[700] md:text-[3vw] tracking-tighter leading-[3.5vw] md:mt-5 md:mb-10 xs:text-[17px] xs:mb-5 xs:mt-7">
            Read these next
          </h2>
          <div className="xs:grid xs:grid-cols-1 sm:grid sm:grid-cols-2  md:grid md:grid-cols-3 md:gap-x-10 md:gap-y-4 md:mt-10 text-start">
            {tsunamicBlog?.map((data) => (
              <div
                key={data?.id}
                className="border border-transparent hover:border-[#BCBCBC] p-3 rounded-3xl flex flex-col justify-center cursor-pointer"
              >
                <img src={data?.img} alt="blogTsunami" />
                <h2 className="font-[700] xs:text-[17px] md:text-[1.25vw] tracking-tighter my-4">
                  {data?.title}
                </h2>

                <div className="font-[500] text-[#979797] md:text-[1.15vw] xs:text-[12px] flex items-center gap-6 tracking-tighter">
                  <p className="border-[1px] border-[#F0F0F0] rounded-full p-2.5">
                    {data?.button}
                  </p>
                  <p>{data?.date}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <FooterData
          content="Subscribe to our Email Newsletter and Stay up to Date with Recent Trends and Advance Trends"
          blogs
        />
      </div>
    </>
  );
};

export default BlogData;
