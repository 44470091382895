import React from "react";
import { allInOneData } from "../constants/home";
import GetStartedButton from "../../../components/button/GetStartedButton";

const AllinOne = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center md:my-20 xs:my-5">
      <h2 className="font-[700] md:text-[2.3vw]">All-in-one: Simple. Smart.</h2>
      <div className="border-[1px] border-[#e1e5f0] rounded-2xl md:my-10 xs:my-5">
        {allInOneData?.map((data) => (
          <div
            key={data?.id}
            className="xs:p-3 text-start md:px-5 md:py-4 border-b-[1px] last:border-b-0 flex items-center"
          >
            <img src={data?.img} alt="img" />
            <div className="pl-5">
              <h2 className="font-[700] md:text-[1.4vw]">{data?.title}</h2>
              <p className="text-[#777c8a] md:text-[1.2vw]">{data?.text}</p>
            </div>
          </div>
        ))}
      </div>
      <GetStartedButton />
    </div>
  );
};

export default AllinOne;
